import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../components/Footer';
import Header from '../components/Header/Main';

const Master = ({
    author,
    children,
    copyright,
    description,
    ldjson,
    robots,
    rating,
    title,
}) => {
    copyright = copyright || 'Driving Test';
    robots = robots || 'index, follow';
    rating = rating || 'general';
    return (
        <div className="flex flex-col h-screen justify-between bg-slate-100">
            <Helmet>
                <title>{title}</title>

                {description && (
                    <meta name="description" content={description} />
                )}

                {author && <meta name="author" content={author} />}

                <meta name="copyright" content={copyright} />

                <meta name="robots" content={robots} />

                <meta name="rating" content={rating} />

                {ldjson && (
                    <script type="application/ld+json">
                        {JSON.stringify(ldjson)}
                    </script>
                )}
            </Helmet>
            <Header />
            <main className="mb-auto">{children}</main>
            <Footer />
        </div>
    );
};
export default Master;
